import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";

import axios from "axios";

import { Wrapper, Modal, Button } from "./styles";
import consts from "../../utils/constants";

const HolidayAlert = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const [holiday, setHoliday] = useState({
    holiday: false,
    text: "",
    name: "",
  });

  const closeModal = () => {
    setIsVisible(false);
    localStorage.setItem(
      "@grupo-aspecir/holiday-alert-modal",
      new Date().toDateString()
    );
  };

  useEffect(() => {
    axios.get(consts.getHolidaysUrl()).then((resp) => {
      let data = resp.data.results[0];
      if (resp.data.results.length > 0) {
        setHoliday({
          holiday: true,
          name: data.name,
          text: data.text,
        });
      } else {
        setHoliday({
          holiday: false,
          name: "",
          text: "",
        });
      }
    });
  }, []);

  // Check if the alert was already been seen
  useEffect(() => {
    if (!checked) {
      let alreadyClecked = localStorage.getItem(
        "@grupo-aspecir/holiday-alert-modal"
      );

      if (alreadyClecked) {
        let checkedDate = new Date(alreadyClecked);
        checkedDate.setDate(checkedDate.getDate() + 1);

        if (checkedDate < new Date()) {
          setIsVisible(true);
        }
      } else {
        setIsVisible(true);
      }
    }

    setChecked(true);
  }, [checked]);

  return (
    <>
      {isVisible && holiday.holiday ? (
        <Wrapper>
          <Modal>
            <div className="modal-header">
              <h2>{holiday.name}</h2>
              <Button onClick={closeModal}>
                <span>Fechar</span>
                <div className="button-icon">
                  <FaTimes />
                </div>
              </Button>
            </div>
            <div className="modal-content">
              <p>{holiday.text}</p>
            </div>
          </Modal>
        </Wrapper>
      ) : null}
    </>
  );
};

export default HolidayAlert;
