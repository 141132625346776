import React, { Component } from "react"
import PropTypes from "prop-types"

class Welcome extends Component {
    render() {
        //Welcome loop start
        const welcomedata = this.props.welcomesData.map((welcome, index) => (
            <a href={welcome.url} target="_blank" className="col-lg-4 col-md-6" key={index}>
                <div className="single-box">
                    <img src={welcome.icon} alt={"ícone " + welcome.title} />

                    <h3>{welcome.title}</h3>
                    <p>{welcome.content}</p>
                </div>
            </a>
        ));

        return (
            <React.Fragment>
                <section className="welcome-area ptb-50">
                    <div className="container">
                        <div className="section-title">
                            <h2>{this.props.sectionTitle} <b>{this.props.sectionTitleSpan}</b></h2>
                        </div>
                        <div className="row">
                            {welcomedata}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

Welcome.propTypes = {
    sectionTitle: PropTypes.string,
    sectionTitleSpan: PropTypes.string,
    plansPageUrl: PropTypes.string,
    welcomesData: PropTypes.array
};

Welcome.defaultProps = {
    sectionTitle: "",
    sectionTitleSpan: "",
    plansPageUrl: "",
    welcomesData: []
};
export default Welcome;
