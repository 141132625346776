let siteId = 2;
const url = process.env.REACT_APP_API_URL;

export default {
  PAGE_GRUPO_ASPECIR: "grupo-aspecir",
  APP_NAME_GRUPO_ASPECIR: "Grupo Aspecir",
  LOGO_GRUPO_ASPECIR: require("../../src/assets/img/logo-grupo-aspecir.png"),
  LOGO_NEGATIVO_GRUPO_ASPECIR: require("../../src/assets/img/logo-grupo-aspecir-negativo.png"),
  PORTAL_CLIENTE_URL: "https://cliente.grupoaspecir.com.br",
  PORTAL_CORRETOR_URL: "https://parceiro.grupoaspecir.com.br",
  ASPECIR_PREVIDENCIA_URL: "https://aspecir.com.br",
  UNIAO_SEGURADORA_URL: "https://uniaoseguradora.com.br",
  ASPECIR_EMPRESTIMOS_URL: "https://aspeciremprestimos.com.br",

  getContactUrl() {
    return `${url}/contato`;
  },

  getNewUrl() {
    return `${url}/noticias`;
  },

  getSiteUrl(page) {
    switch (page) {
      case this.PAGE_GRUPO_ASPECIR:
        return `${url}/sites/${siteId}`;
      default:
        return null;
    }
  },

  getSitePageUrl() {
    return `${url}/sites/${siteId}`;
  },

  getNewsUrl(page) {
    return `${url}/sites/${siteId}/noticias` + "?page=" + (page || "1");
  },

  getApiChatBotUrl() {
    return url + "/chat_bot";
  },

  getHolidaysUrl() {
    return `${url}/holidays_config?active_holiday=true`;
  },

  getInternalPageData(page) {
    let site, logo, logoNegativo;

    switch (page) {
      case this.PAGE_GRUPO_ASPECIR:
        site = this.APP_NAME_GRUPO_ASPECIR;
        logo = this.LOGO_GRUPO_ASPECIR;
        logoNegativo = this.LOGO_NEGATIVO_GRUPO_ASPECIR;
        break;
      default:
        return;
    }

    return [site, logo, logoNegativo];
  },
};
